<template>
  <vx-card :title="title">
    <vs-tabs :value="active">
      <vs-tab label="SAF">
        <Saf></Saf>
      </vs-tab>
      <vs-tab label="Approved">
        <Approved></Approved>
      </vs-tab>
      <vs-tab label="Close SAF">
        <CloseSaf></CloseSaf>
      </vs-tab>
      <vs-tab label="Canceled">
        <Canceled></Canceled>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>
<script>
import Saf from "./_tab/SafTab.vue"
import Approved from "./_tab/ApprovedTab.vue"
import CloseSaf from "./_tab/CloseSafTab.vue"
import Canceled from "./_tab/CanceledTab.vue"

export default {
  props: {
    active: {
      type: Number,
    },
  },
  components: {
    Saf,
    Approved,
    CloseSaf,
    Canceled
  },
  data() {
    return {
      title: "Stock Adjustment Form",
    };
  },
};
</script>
